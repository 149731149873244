







































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import SpamReactionHandlerModuleBuilder from '@/includes/logic/Modules/models/modules/SpamReactionHandlerModule/SpamReactionHandlerModuleBuilder'
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue';
import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettings,
    ModuleConfigSettingsWrapper,
    SwitchInput,
    TimeUnitInput,
    NumberInput,
    BlackWhiteListInput,
    NotAvailableOptionsOverlay,
    NestedContent
  },
})
export default class SpamReactionHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: SpamReactionHandlerModuleBuilder

  get tariffTags() {
    return this.module.tariffTags()
  }

  get tags() {
    return [ ...this.tariffTags || [], ...this.module.tags() || [], ]
  }

  mounted() {
    if (!this.tariffTags) {
      this.$baseTemplate.saveButton.show()
    } else {
      this.$baseTemplate.saveButton.hide()
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spam-reaction-handler-module-view"},[_c('page-title',{attrs:{"tags":_vm.tags,"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.tariffTags)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'handle_channel',
          'hasAnchor': true,
          'disabled': _vm.disabled
        },
      }}}),(_vm.module.model.params.handle_channel)?_c('nested-content',{staticClass:"mb-0"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'channel_max_subscribers',
            'min': 0,
            'max': Number.MAX_SAFE_INTEGER,
            'hasAnchor': true,
            'settingWrapper': {
              customiseValue: 50,
              hasFieldButton: true,
              disabledValue: 0
            },
            'disabled': _vm.disabled
          }
        }}}),_c('black-white-list-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'channel_whitelist',
            'settingWrapper': {
              'hasFieldButton': true,
              'disabledValue': {
                type: 'Blacklist',
                items: [ ]
              },
              'customiseValue': {
                type: 'Blacklist',
                items: [ ]
              },
            },
            'disabled': _vm.disabled
          }
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'spam_reaction_count',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 50,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.model.params.spam_reaction_count > 0)?_c('nested-content',[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'spam_reaction_interval',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'old_message_reaction_count',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 50,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.model.params.old_message_reaction_count > 0)?_c('nested-content',[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'old_message_interval',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }